import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { prefix } from "../components/offer-section.utils";
import offerDetailsCss from "../components/offer-details.module.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import Showcase from "../components/showcase";

// style
const pStyles = {
  lineHeight: "1.4rem",
  margin: "1rem 0",
};

const background = "#20222d";

// data
const meta = [
  {
    img: "plisy-1",
    text: {
      title: "Najcześciej stosowany układ plis",
      description:
        "umożliwiają ustawienie dopływu promieni słonecznych w dowolny sposób, od góry, od dołu, jak na załączonej realizacji",
    },
  },
  {
    img: "plisy-2",
    text: {
      title: "Plisa DUO",
      description:
        "możliwość zastosowania dwóch różnych kolorów materiałów na jednym oknie",
    },
  },
  {
    img: "plisy-3",
    text: {
      title: "Plisa z powłoką perłową",
      description: "kolorystyka dobrana do nowoczesnych stylizacji wnętrza",
    },
  },
  {
    img: "plisy-4",
    text: {
      title: "Plisa kaszmirowa",
      description: "nie należy bać się mocnych kolorów",
    },
  },
  {
    img: "plisy-5",
    text: {
      title: "Plisa plaster miodu",
      description:
        "fantastyczna izolacja termiczna - możliwość przysłonięcie dużych powierzchni okien",
    },
  },
  {
    img: "plisy-6",
    text: {
      title: "Plisa z fakturą bambusową",
      description:
        "doskonale sprawdzają się we wszystkich pomieszczeniach, również w kuchni",
    },
  },
  {
    img: "plisy-7",
    text: {
      title: "Plisa ze strukturą workową",
      description:
        "szeroka gama kolorów i faktur materiałów, także z powłoką aluminiową zadowoli najbardziej wymagających klientów",
    },
  },
  {
    img: "plisy-8",
    text: {
      title: "Plisa materiałowa",
      description: "plisy i dodatki w tym samym kolorze  to ciekawa propozycja",
    },
  },
  {
    img: "plisy-9",
    text: {
      title: "EKO plisa",
      description: "ekologiczny design - materiały w 100% z recyklingu",
    },
  },
];

// markup
const PlisyPage = () => {
  // data
  const descriptions = [
    "Rolety plisowane są nieszablonowym wyborem dla wymagających. Posiadają one wiele funkcjonalności dzięki czemu świetnie się sprawdzą w nietypowych środowiskach. Ich mocną stroną jest możliwość dopasowania do różnej specyfiki okien. Są naturalnym wyborem przy oknach dachowych i o nieregularnych kształtach.",
    "Skutecznie chronią przed intensywnym nasłonecznieniem pomieszczenia i zapobiegają nagrzewaniu pokoi. Plisy oferują regulację wysokości na poziomie góra-dół, daje to możliwość regulacji metody oświetlania wnętrza. Ogromną zaletą plis jest łatwość ich utrzymania, są bardzo proste do czyszczenia, proces ten nie wymaga specjalnych narzędzi i nie jest pracochłonny. Praktyczność, łatwość utrzymania, ergonomia i atrakcyjny wygląd sprawiają, że plisy cieszą się pochlebnymi opiniami klientów.",
  ];

  const { images } = useStaticQuery(graphql`
    query PlisyImages {
      images: allFile(
        filter: { relativeDirectory: { eq: "showcase/plisy" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const final = images.edges.map((edge) => {
    const texts = meta.find((m) => m.img === edge?.node?.name);
    edge.node.meta = { ...texts.text };
    return edge;
  });
  return (
    <Layout>
      <SEO
        title="Rolety Lider | Plisy | Plisy Bambusowe"
        description="Plisy w szerokiej gamie kolorów i materiałów. Tylko sprawdzeni producenci."
      />
      <section style={{ marginTop: "-6.1rem" }}>
        {prefix(true, background)}
        <article
          style={{
            background,
          }}
          className={offerDetailsCss.article}
        >
          <PageTitle showBack="true" title="Plisy" />
          <div className={offerDetailsCss.text}>
            {descriptions.map((text, i) => (
              <p key={i} style={pStyles}>
                {text}
              </p>
            ))}
          </div>
          <Showcase images={final} />
        </article>
      </section>
    </Layout>
  );
};

export default PlisyPage;
